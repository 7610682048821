import axios from 'axios';
import Config from 'config';
import { UserDto } from 'types/UserDto';

export const fetchOwner = async (personId: string, userName: string, displayName: string): Promise<UserDto> => {
  const body = {
    "personId": personId,
    "name": displayName,
    "userName": userName
  };

  const res = await axios.post(`${Config.baseApiUrl}/user`, body, {
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
  });

  if (!res.status) { 
    throw new Error(`status: ${res.status}`);
  }

  const data = res.data;  
  return data;
};