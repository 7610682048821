import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField
} from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import 'dayjs/locale/is';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
// Atoms
import * as AssignmentAtoms from 'Atoms/AssignmentAtoms';
import * as GridAtoms from "../../Atoms/WorkAssignmentGridAtoms";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { OwnerIdAtom, UserAtom } from "Atoms/AuthAtoms";
import PreSelectedAutocomplete from "components/Common/PreSelectedAutocomplete";
import { SettingKey } from "constants/SettingKey";
import { useFetchsettings } from "hooks/SettingHooks";
import { useTranslation } from "react-i18next";
import {
  useFetchMaintenancePlants,
  useFetchPlanningPlants,
  useFetchWorkAssignment,
  useFetchWorkCenters,
} from "hooks/WorkAssignmentHooks";
import { AutoCompleteValues } from "types/AutoCompleteValues";
import { dateLimit } from "utilities/DatesFunction";
import { updateSelectedItems, useResetRightFilter } from "utilities/ResetFilters";
import { CustomTabPanel } from "./CustomTabPanel";
import { defaultDateRange } from 'utilities/DatesFunction';
import PreSelectedAutocompleteSelectAll from "components/Common/PreSelectedAutocompleteSelectAll";
import { StylingConstants } from "constants/Constants";
import { format } from 'react-string-format';
import { selectedLanguageAtom } from "Atoms/CommonAtoms";
/**
 * This component is responsible for displaying a Main top filters for work assignments.
 */

const WorkAssignmentHeader = () => {
  // Get/set atoms from the state
  const [t, ] = useTranslation();
  const [selectedLanguage, ] = useAtom(selectedLanguageAtom);
  const [, setInitialWAData] = useAtom(GridAtoms.initialWADataAtom);
  const [, setWorkAssignmentData] = useAtom(GridAtoms.workAssignmentDataAtom);
  const [, setgridDataLoadingAtom] = useAtom(GridAtoms.gridDataLoadingAtom);
  const [selectedPlanningPlants, setSelectedPlanningPlants] = useAtom(
    GridAtoms.selectedPlanningPlantsAtom
  );
  const [selectedMaintPlants, setselectedMaintPlants] = useAtom(
    GridAtoms.selectedMaintPlantsAtom
  );
  const [selectedWorkCenters, setSelectedWorkCenters] = useAtom(
    GridAtoms.selectedWorkCentersAtom
  );
  const [refreshGridCount,setRefreshGridCount] = useAtom(GridAtoms.refreshGridDataAtom);
  const [workOrderNo, setWorkOrderNo] = useAtom(GridAtoms.workOrderNoAtom);  
  const [selectedOperationIds,] = useAtom(AssignmentAtoms.selectedOperationIdsAtom);
  const [dateRange, setDateRange] = useAtom(GridAtoms.dateRangeAtom);
  const [filters] = useAtom(GridAtoms.filtersAtom);
  const [OwnerId, ] = useAtom(OwnerIdAtom);
  const [User, ] = useAtom(UserAtom);
  const resetRightFilter = useResetRightFilter();
  const {
    data: PlanningPlantsettings
  } = useFetchsettings(OwnerId, SettingKey.PlanningPlant);
  const {
    data: MaintPlantsettings
  } = useFetchsettings(OwnerId, SettingKey.MaintenancePlant);
  const {
    data: WorkCentersSettings   
  } = useFetchsettings(OwnerId, SettingKey.WorkCenters);
  // Fetch planning plants and maintenance plants based on selected planning plants
  const { data: planningPlants, isLoading: isPlanningPlantLoading } =
    useFetchPlanningPlants(User.companyCode);
  const { data: MaintenancePlants, isLoading: isMaintPlantLoading } =
    useFetchMaintenancePlants(
      selectedPlanningPlants.map(({ id }) => id)
    );
    const { marginRight, marginBottom, minWidth, maxWidth } = StylingConstants;
       
    const searchButtonVariant = selectedOperationIds.length>0 ? 'outlined' : 'contained';

  // Fetch work centers based on selected planning plants and maintenance plants
  const { data: workCenters, isLoading: isWorkCenterLoading } =
    useFetchWorkCenters(selectedPlanningPlants.map(({ id }) => id));
  // State variables
  const [isPlantSubmitDisabled, setPPIsSubmitDisabled] = useState(true); 
  const [isWOSubmitDisabled, setWOIsSubmitDisabled] = useState(true); 
  const resetFiltersState = () => {
    setInitialWAData([]);
    setWorkAssignmentData([]);
    setSelectedPlanningPlants([]);
    setselectedMaintPlants([]);
    setSelectedWorkCenters([]);
    updateSelectedItems(PlanningPlantsettings ?? [], planningPlants ?? [], setSelectedPlanningPlants);
    updateSelectedItems(MaintPlantsettings ?? [], MaintenancePlants ?? [], setselectedMaintPlants);
    updateSelectedItems(WorkCentersSettings ?? [], workCenters ?? [], setSelectedWorkCenters);
    setWorkOrderNo("");
    setDateRange([defaultDateRange.start, defaultDateRange.end]);
  };

  const [tabvalue, setTabvalue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabvalue(newValue);
    resetFiltersState(); 
    setWorkOrderNo("");
    setDateRange([defaultDateRange.start, defaultDateRange.end]);
    setInitialWAData([]);
    setWorkAssignmentData([]);
    newValue===0 ? t('NoOperationsFoundTextForPlantText') : format(t('NoOperationsFoundTextForWOText'), dateLimit.min.format("DD/MM/YYYY"), dateLimit.max.format("DD/MM/YYYY"));
  };
  
  const [error, setError] = useState<{
    planningPlant: string;
    workCenter: string;
    startDate:boolean;
    endDate:boolean;
  }>({
    planningPlant: "",
    workCenter: "",
    startDate:false,
    endDate:false
  });
  useEffect(() => {    
    setInitialWAData([]);
    setWorkAssignmentData([]);
    resetFiltersState(); 
    setWorkOrderNo("");
    setDateRange([defaultDateRange.start, defaultDateRange.end]);
  }, []);

  useEffect(() => {
    updateSelectedItems(PlanningPlantsettings??[], planningPlants??[], setSelectedPlanningPlants);    
    }, [PlanningPlantsettings, planningPlants]);

 
  useEffect(() => {
    updateSelectedItems(MaintPlantsettings??[], MaintenancePlants??[], setselectedMaintPlants);
  }, [MaintPlantsettings, MaintenancePlants]);

  useEffect(() => {
    updateSelectedItems(WorkCentersSettings??[], workCenters??[], setSelectedWorkCenters);    
  }, [WorkCentersSettings, workCenters]);

  useEffect(() => {
   
      setWOIsSubmitDisabled(workOrderNo.length < 1);
    
  }, [workOrderNo]);

  // Fetch filtered work assignment data based on the current filters
  const { data: filteredData, isLoading: isFilteredDataLoading } = useFetchWorkAssignment(filters,workOrderNo,tabvalue);

  useEffect(() => {
    setgridDataLoadingAtom(isFilteredDataLoading);
    if(!isFilteredDataLoading){
      setWorkAssignmentData(filteredData ?? []);
      setInitialWAData(filteredData ?? []);
    }
  }, [refreshGridCount, isFilteredDataLoading]);

  useEffect(() => {
    if((filteredData??[]).length>0){
      resetRightFilter();
    }
    else
    {
      resetRightFilter();
    }
  }, [filteredData]);

  // Custom parse format for dayjs library
  const customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  
  const handlePlanningPlantChange = (values:AutoCompleteValues[]) => {
    setSelectedPlanningPlants(values);

    // Clear selected Maintenance Plants if no Planning Plants are selected
    if (values.length === 0) {
      setselectedMaintPlants([]);
      setSelectedWorkCenters([]);
      setInitialWAData([]);
    }
  };

  useEffect(() => {
    // Initialize error messages
    setError({ planningPlant: "", workCenter: "",startDate:false,endDate:false});

        // Disable submit button if either planning plant or work center is not selected
    setPPIsSubmitDisabled(
      !selectedPlanningPlants.length || !selectedWorkCenters.length);
      if (!selectedPlanningPlants.length) {
        setError((prevError) => ({
          ...prevError,
          planningPlant: t('PlanningPlantRequiredText'),
        }));
      }

      if (!selectedWorkCenters.length) {
        setError((prevError) => ({
          ...prevError,
          workCenter: t('WorkCenterRequiredText'),
        }));
      }
  }, [selectedPlanningPlants, selectedWorkCenters]);

  useEffect(() => {
        setError((prevError) => ({
      ...prevError,
      startDate: dateRange[0] === null,
      endDate: dateRange[1] === null,
    }));
  }, [dateRange]);

  useEffect(() => {
    setPPIsSubmitDisabled(error.startDate || error.endDate || error.planningPlant.length>0 || error.workCenter.length>0);    
    setWorkAssignmentData([]);
    setInitialWAData([]);
  }, [error]);

  const handleDateChange = (newValue: DateRange<dayjs.Dayjs>) => {
    setDateRange(newValue);
  };

  const handleClearAll = () => {
    setSelectedPlanningPlants([]);
    setselectedMaintPlants([]);
    setSelectedWorkCenters([]);
    setWorkOrderNo("");
    setDateRange([defaultDateRange.start, defaultDateRange.end]);
  };
  const handleSubmit = () => {
    setRefreshGridCount(prevRefreshGridCount => prevRefreshGridCount + 1); 
  };
  
  const onKeyDown = (e:any) => {
    e.preventDefault();
  };

 return (
    <>
   <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={tabvalue} onChange={handleChange} aria-label={t('SearchTabsText')}>
          <Tab label= {t("SearchByPlantsText")} id="tab-1"/>
          <Tab label= {t("SearchByWorkOrderText")} id="tab-2" />       
        </Tabs>
      </Box>
    <CustomTabPanel value={tabvalue} index={0}>
      <Grid container>  
      <Grid item sx={{
        alignContent: "center",  
         marginRight:marginRight,
         marginBottom:marginBottom,
         maxWidth:minWidth,
         minWidth:maxWidth
      }}>
        <PreSelectedAutocomplete
          options={planningPlants ?? []}
          label={t("PlanningPlantsText")}
          onChange={handlePlanningPlantChange}
          selectedValues={selectedPlanningPlants}
          required={true}
          isConcateText={true}
          isLoading={isPlanningPlantLoading}    
          height="50px"
        
        />
      </Grid>    
      <Grid item sx={{
        alignContent: "center",  
         marginRight:marginRight,
         marginBottom:marginBottom,
         maxWidth:minWidth,
         minWidth:maxWidth
      }}>
         <PreSelectedAutocompleteSelectAll
          options={workCenters ?? []}
          label={t("WorkCenterText")}
          onChange={setSelectedWorkCenters}
          selectedValues={selectedWorkCenters}
          required={true}
          isConcateText={true}
          isLoading={isWorkCenterLoading}
          renderCode={true}
          height="50px"
        />
      </Grid>
      <Grid item sx={{
        alignContent: "center",  
         marginRight:marginRight,
         marginBottom:marginBottom,
         maxWidth:minWidth,
         minWidth:maxWidth
      }}>
        <PreSelectedAutocomplete
          options={MaintenancePlants ?? []}
          label={t("MaintenancePlantText")}
          onChange={setselectedMaintPlants}
          selectedValues={selectedMaintPlants}
          required={false}
          isConcateText={true}
          isLoading={isMaintPlantLoading}
          height="50px"
        />
      </Grid>
      <Grid item sx={{
        alignContent: "center",  
         marginRight:marginRight,
         marginBottom:marginBottom,
         maxWidth:minWidth,
         minWidth:maxWidth
      }}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={selectedLanguage}
          localeText={{ start: t("ScheduledStartFromText"), end: t("ScheduledStartToText") }}>
          <DateRangePicker
            value={dateRange}
            inputFormat={'DD/MM/YYYY'}
            minDate={dateLimit.min}
            maxDate={dateLimit.max}
            onChange={handleDateChange}
            renderInput={(startProps, endProps) => (
              <>
                <TextField
                  fullWidth
                  onKeyDown={onKeyDown}
                  required
                  size="small"
                  InputLabelProps={{
                    style: { fontSize: 14 },
                  }}
                  {...startProps}
                  sx={{
                    marginRight: 1,
                    paddingTop: 0.1                   
                  }}
                  error={error.startDate}
                  helperText={error.startDate ? t('StartDateRequiredText') : ""}
                />
                <Box>{t('GenericToText')}</Box>

                <TextField
                  fullWidth
                  onKeyDown={onKeyDown}
                  required
                  size="small"                 
                  {...endProps}
                  sx={{
                    marginLeft: 1,
                    paddingTop: 0.1                  
                  }}
                  error={error.endDate}
                  helperText={error.endDate ? t('EndDateRequiredText') : ""}
                />
              </>
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item
      sx={{
        alignContent: "center",  
         marginRight:marginRight,
         marginBottom:marginBottom,
         maxWidth:minWidth,
         minWidth:maxWidth
      }}>
       <Button
          variant={searchButtonVariant}
          disabled={isPlantSubmitDisabled}
          onClick={handleSubmit}          
        >          
          <SearchIcon fontSize="small"/>
          {t("SearchText")}
        </Button>       
     
        <Button
          variant="text"          
          onClick={handleClearAll} 
          disabled={isPlantSubmitDisabled}            
        >          
          <CloseIcon fontSize="small"/>
          {t("ClearAllText")}
        </Button>       
      </Grid> 
    </Grid>
      </CustomTabPanel>   
      <CustomTabPanel value={tabvalue} index={1}>
      <Grid container>  
      <Grid item sx={{
         alignContent: "center",  
         marginRight:marginRight,
         marginBottom:3,
         maxWidth:minWidth,
         minWidth:maxWidth
      }}>
        <TextField
          id="txtWorkOrderNo"
          label={t("WorkOrderText")}
          variant="outlined"
          size="small"         
          fullWidth={true}
          inputProps={{ maxLength: 120 }}
          InputLabelProps={{
            style: { fontSize: 12 },
          }}
          value={workOrderNo}
          onChange={(e) => setWorkOrderNo(e.target.value)}
        />
      </Grid>      
      <Grid item sx={{
      alignContent: "center",  
      marginRight:marginRight,
      marginBottom:3,
      maxWidth:minWidth,
      minWidth:maxWidth
      }}>
          <Button
          variant={searchButtonVariant}
          disabled={isWOSubmitDisabled}
          onClick={handleSubmit}
        >          
          <SearchIcon fontSize="small"/>
          {t("SearchText")}
        </Button>       
             
        <Button
          variant="text"          
          onClick={handleClearAll} 
          disabled={isWOSubmitDisabled}            
        >          
          <CloseIcon fontSize="small"/>
          {t("ClearAllText")}
        </Button>   
      </Grid> 
    </Grid>
      </CustomTabPanel>     
   
    </Box>   
    </>
  );
};

export default WorkAssignmentHeader;