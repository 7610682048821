import {
  Grid,
  Typography
} from '@mui/material';
import WorkAssignmentGrid from 'components/WorkAssignmnetGrid/WorkAssignmentGrid';
import WorkAssignmentHeader from 'components/WorkAssignmnetGrid/WorkAssignmentHeader';
import { useTranslation } from "react-i18next";

export const WorkAssignmentPage = () => {
  const [t, ] = useTranslation();
  return (
    <>
      <Grid container direction="column">
        <Grid item marginBottom="0.5em">
          <Typography variant="subtitle1" fontWeight={600}>
            {t('WorkAssignmentText')}
          </Typography>
        </Grid>
        
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <WorkAssignmentHeader />
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <WorkAssignmentGrid gridHeight="71vh"/>
        </Grid>
        </Grid>
      </Grid>
    </>
  );
};
