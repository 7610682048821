import dayjs from "dayjs";
import weekday from 'dayjs/plugin/weekday';
import utc from 'dayjs/plugin/utc';
dayjs.extend(weekday);
dayjs.extend(utc);
export const defaultDateRange: { start: dayjs.Dayjs; end: dayjs.Dayjs; } = { 
 
  
    start: dayjs(), end: (dayjs().endOf('week').add(1,'day'))
};
export const dateLimit: { min: dayjs.Dayjs; max: dayjs.Dayjs; } = { 
   
    min: (dayjs().weekday(1).subtract(1,"week")), max: (dayjs().add(13, "day"))
};

  
