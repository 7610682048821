import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { AssignSyncCheckbox } from "./AssignSyncCheckbox";
import { useTranslation } from "react-i18next";


export const AssignmentColumns = (): GridColDef[] => {
  const {t,} = useTranslation();
  return [
    {
      field: 'assign',  
      headerName: t("AssignOperationText"),
      align: 'center',
      headerAlign: 'center',
      flex: 1, 
      renderCell: (params) => {
        return (
          <AssignSyncCheckbox rowId={params.row.id} isAssign={true} plmAccess={params.row.plmAccess}/>
        );
      }
    },
    { 
      field: 'sync', 
      headerName: t("SyncToPLMText"),
      flex: 0.8,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <AssignSyncCheckbox rowId={params.row.id} isAssign={false} plmAccess={params.row.plmAccess}/>
        );
      }
    },
    { 
      field: 'personnelNumber', 
      headerName: t("PersonnelNumberText"), 
      align: 'center',
      headerAlign: 'center', 
      flex: 1 
    },
    { 
      field: 'name', 
      headerName: t("FullNameText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 1.5 
    },
    { 
      field: 'jobTitle', 
      headerName: t("JobTitleText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 1.5 
    },
    { 
      field: 'isActive', 
      headerName: t("StatusText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 0.8, 
      valueGetter: (params: GridValueGetterParams) => params.row.isActive ? t('GenericActiveText') : t('GenericInactiveText')
    },
    { 
      field: 'userType', 
      headerName: t("TypeText"),
      align: 'center',
      headerAlign: 'center',
      flex: 0.8 
    },
    { 
      field: 'operationsCount', 
      headerName: t("OperationsText"), 
      align: 'center',
      headerAlign: 'center',
      flex: 0.8 
    },
    { 
      field: 'operationsDuration', 
      headerName: t("TotalTextDuration"), 
      align: 'center',
      headerAlign: 'center',
      flex: 1.2,
      valueGetter: (params: GridValueGetterParams) => params.row.operationsDuration.toFixed(1) + ' ' + t("HourAbbrText")
    },
  ]
};
